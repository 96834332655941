import { GetCurrentUser } from "@/graphql/queries.js";
import { API, graphqlOperation } from "aws-amplify";

export const GetCurrentUserDetails = {
  data() {
    return {
      getCurrentUserDetailsObject: null,
      overlay: false,
      user_type: "",
    };
  },
  methods: {
    async GetCurrentUserDetailsMethod(sessionRefresh) {
      if (this.$store.getters.get_user_email != "") {
        try {
          this.overlay = true;

          let result = await API.graphql(
            graphqlOperation(GetCurrentUser, {
              input: {
                user_email_id: this.$store.getters.get_user_email.trim(),
              },
            })
          );

          const resultObj = JSON.parse(result.data.GetCurrentUser);
          if (resultObj.status == "Success") {
            this.getCurrentUserDetailsObject = JSON.parse(result.data.GetCurrentUser).items.user_data;
            this.$store.commit("SET_CURRENTUSER_DETAILS", this.getCurrentUserDetailsObject);
            if (sessionRefresh) {
              this.overlay = false;
              return;
            }

            this.overlay = false;
            return "SUCCESS";
          } else {
            if (resultObj.message === "No ACTIVE user found!");
            // this.$store.commit("SET_USEREMAIL", "");
            // this.$store.commit("SET_CURRENTUSER_DETAILS", null);
            // this.$router.replace("/");
            this.forceLogoutPopup = true;
          }
        } catch (error) {
          console.log(error);
          this.overlay = false;
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            Top: true,
            SnackbarText: error,
          };
          this.$store.commit("SET_USEREMAIL", "");
          this.$store.commit("SET_CURRENTUSER_DETAILS", null);
          // this.$router.push("/");
          // return "ERROR";
        }
      } else {
        this.$router.push("/");
      }
    },
  },
};
