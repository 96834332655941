import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
    EmailID: "",
    get_current_user_details: null,
    currentRouteObject: {},
    form_details: {},
    is_login_attempt: false,
    ticket_filters: null,
  },
  getters: {
    get_user_email: (state) => {
      return state.EmailID;
    },
    get_current_user_details(state) {
      return state.get_current_user_details;
    },
    get_current_route_object: (state) => {
      return state.currentRouteObject;
    },
    get_form_details(state) {
      return state.form_details;
    },
    get_is_login_attempt(state) {
      return state.is_login_attempt;
    },
    get_ticket_filters(state) {
      return state.ticket_filters;
    },
  },
  mutations: {
    SET_USEREMAIL(state, username) {
      state.EmailID = username;
    },
    SET_CURRENTUSER_DETAILS(state, userDetails) {
      state.get_current_user_details = userDetails;
    },
    SET_CURRENT_ROUTE_OBJECT(state, currentRouteObject) {
      state.currentRouteObject = currentRouteObject;
    },
    SET_FORM_DETAILS(state, formDetails) {
      state.form_details = formDetails;
    },
    SET_IS_LOGIN_ATTEMPT(state, is_login_attempt) {
      state.is_login_attempt = is_login_attempt;
    },
    SET_TICKET_FILTERS(state, ticket_filters) {
      state.ticket_filters = ticket_filters;
    },
  },
  actions: {},
  modules: {},
});
