import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import store from "./store";
import router from "./router";
import { Amplify } from "aws-amplify";
import VueApexCharts from "vue-apexcharts";

Vue.config.productionTip = false;

Amplify.configure({
  Auth: {
    userPoolId: process.env.VUE_APP_AWS_COGNITO_USER_POOL_ID,
    region: process.env.VUE_APP_AWS_REGION,
    userPoolWebClientId: process.env.VUE_APP_AWS_USER_POOL_WEB_CLIENT_ID,
  },
  API: {
    aws_project_region: process.env.VUE_APP_AWS_PROJECT_REGION,
    aws_appsync_graphqlEndpoint: process.env.VUE_APP_AWS_GRAPHQL_ENDPOINT,
    aws_appsync_region: process.env.VUE_APP_AWS_APPSYNC_REGION,
    aws_appsync_authenticationType: process.env.VUE_APP_AWS_AUTHENTICATION_TYPE,
  },
});
Vue.component("apexchart", VueApexCharts);
new Vue({
  vuetify,
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");
