import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#f47f40",
        secondary: "#343f52",
        pageBackground: "#efecec",
        redChipColor: "#ffebf9",
        redChipTextColor: "#dc56a6",
        greenChipColor: "#cafae3",
        greenChipTextColor: "#376260",
        yellowChipColor: "#f8eebf",
        yellowChipTextColor: "#817232",
        purpleChipColor: "#e3e5fd",
        purpleChipTextColor: "#4f58c9",
        blueChipColor: "#c5e0ff",
        blueChipTextColor: "#6292c7",
        darkgreyColor: "#000000",
        textColor: "#000000", // Default text color for light theme
        warning: "#ff9800",
        info: "#2196f3",
        success: "#4caf50",
        error: "#f44336",
        background: "#ffffff", // Default background color for light theme
        cardBgColor: "#f47f40",
        bluetext: "#3f11f7",
      },
      dark: {
        primary: "#f47f40",
        secondary: "#343f52",
        pageBackground: "#373A40",
        redChipColor: "#ffebf9",
        redChipTextColor: "#dc56a6",
        greenChipColor: "#ecfdff",
        greenChipTextColor: "#376260",
        yellowChipColor: "#f8eebf",
        yellowChipTextColor: "#817232",
        purpleChipColor: "#e3e5fd",
        purpleChipTextColor: "#4f58c9",
        blueChipColor: "#c5e0ff",
        blueChipTextColor: "#6292c7",
        darkgreyColor: "#ffffff",
        textColor: "#ffffff", // Default text color for dark theme
        warning: "#ffa726",
        info: "#29b6f6",
        success: "#66bb6a",
        error: "#ef5350",
        background: "#121212", // Default background color for dark theme
        cardBgColor: "#545454",
      },
    },
    options: { customProperties: true },
    dark: false, // Set to true to enable dark theme by default
  },
});
