<template>
  <v-snackbar color="#ffffff" v-model="SnackBarComponent.SnackbarVmodel" elevation="24" top :timeout="SnackBarComponent.timeout">
    <div class="text-center" :class="`${SnackBarComponent.SnackbarColor}--text`">
      <v-icon small left :color="SnackBarComponent.SnackbarColor">{{
        SnackBarComponent.SnackbarColor == "green" ? "mdi-checkbox-marked-circle" : SnackBarComponent.SnackbarColor == "red" ? "mdi-close-circle" : ""
      }}</v-icon
      >{{ SnackBarComponent.SnackbarText }}
    </div>
  </v-snackbar>
</template>
<script>
export default {
  props: {
    SnackBarComponent: Object,
  },
};
</script>
