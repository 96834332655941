/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const GetCurrentUser = /* GraphQL */ `
  query GetCurrentUser($input: GetCurrentUserInput!) {
    GetCurrentUser(input: $input)
  }
`;
export const GetPackageDetails = /* GraphQL */ `
  query GetPackageDetails($input: GetPackageDetailsInput!) {
    GetPackageDetails(input: $input)
  }
`;
export const GetFieldRepo = /* GraphQL */ `
  query GetFieldRepo($input: GetFieldRepoInput!) {
    GetFieldRepo(input: $input)
  }
`;
export const ListRoles = /* GraphQL */ `
  query ListRoles($input: ListRolesInput!) {
    ListRoles(input: $input)
  }
`;
export const ListUsersOfDepartment = /* GraphQL */ `
  query ListUsersOfDepartment($input: ListUsersOfDepartmentInput!) {
    ListUsersOfDepartment(input: $input)
  }
`;
export const ListAllModules = /* GraphQL */ `
  query ListAllModules($input: ListAllModulesInput!) {
    ListAllModules(input: $input)
  }
`;
export const ListAllSubModules = /* GraphQL */ `
  query ListAllSubModules($input: ListAllSubModulesInput!) {
    ListAllSubModules(input: $input)
  }
`;
export const GetRoleUsers = /* GraphQL */ `
  query GetRoleUsers($input: GetRoleUsersInput!) {
    GetRoleUsers(input: $input)
  }
`;
export const ListUsers = /* GraphQL */ `
  query ListUsers($input: ListUsersInput!) {
    ListUsers(input: $input)
  }
`;
export const ListFieldsOfMasterForm = /* GraphQL */ `
  query ListFieldsOfMasterForm($input: ListFieldsOfMasterFormInput!) {
    ListFieldsOfMasterForm(input: $input)
  }
`;
export const ListDepartments = /* GraphQL */ `
  query ListDepartments($input: ListDepartmentsInput!) {
    ListDepartments(input: $input)
  }
`;
export const ListAvailableUsers = /* GraphQL */ `
  query ListAvailableUsers($input: ListAvailableUsersInput!) {
    ListAvailableUsers(input: $input)
  }
`;
export const ListCRMLogs = /* GraphQL */ `
  query ListCRMLogs($input: ListCRMLogsInput!) {
    ListCRMLogs(input: $input)
  }
`;
export const ListLeadsOfLog = /* GraphQL */ `
  query ListLeadsOfLog($input: ListLeadsOfLogInput!) {
    ListLeadsOfLog(input: $input)
  }
`;
export const ListAgents = /* GraphQL */ `
  query ListAgents($input: ListAgentsInput!) {
    ListAgents(input: $input)
  }
`;
export const ListWorkqueues = /* GraphQL */ `
  query ListWorkqueues($input: ListWorkqueuesInput!) {
    ListWorkqueues(input: $input)
  }
`;
export const ListStages = /* GraphQL */ `
  query ListStages($input: ListStagesInput!) {
    ListStages(input: $input)
  }
`;
export const GetWorkqueueInfo = /* GraphQL */ `
  query GetWorkqueueInfo($input: GetWorkqueueInfoInput!) {
    GetWorkqueueInfo(input: $input)
  }
`;
export const ListCuratedDataLogs = /* GraphQL */ `
  query ListCuratedDataLogs($input: ListCuratedDataLogsInput!) {
    ListCuratedDataLogs(input: $input)
  }
`;
export const ExotelGetCallInformation = /* GraphQL */ `
  query ExotelGetCallInformation($input: ExotelGetCallInformationInput!) {
    ExotelGetCallInformation(input: $input)
  }
`;
export const ListTickets = /* GraphQL */ `
  query ListTickets($input: ListTicketsInput!) {
    ListTickets(input: $input)
  }
`;
export const ListClosedTickets = /* GraphQL */ `
  query ListClosedTickets($input: ListClosedTicketsInput!) {
    ListClosedTickets(input: $input)
  }
`;
export const ListMyTickets = /* GraphQL */ `
  query ListMyTickets($input: ListMyTicketsInput!) {
    ListMyTickets(input: $input)
  }
`;
export const ListRftTickets = /* GraphQL */ `
  query ListRftTickets($input: ListRftTicketsInput!) {
    ListRftTickets(input: $input)
  }
`;
export const ListArchiveTickets = /* GraphQL */ `
  query ListArchiveTickets($input: ListArchiveTicketsIndex!) {
    ListArchiveTickets(input: $input)
  }
`;
export const FetchLeadIncomingCall = /* GraphQL */ `
  query FetchLeadIncomingCall($input: FetchLeadIncomingCallInput!) {
    FetchLeadIncomingCall(input: $input)
  }
`;
export const ListDepartmentTickets = /* GraphQL */ `
  query ListDepartmentTickets($input: ListDepartmentTicketsInput!) {
    ListDepartmentTickets(input: $input)
  }
`;
export const GetClients = /* GraphQL */ `
  query GetClients($input: GetClientsInput!) {
    GetClients(input: $input)
  }
`;
export const GetProductsOfClient = /* GraphQL */ `
  query GetProductsOfClient($input: GetProductsOfClientInput!) {
    GetProductsOfClient(input: $input)
  }
`;
export const ListProductsOfOrganisation = /* GraphQL */ `
  query ListProductsOfOrganisation($input: ListProductsOfOrganisationInput!) {
    ListProductsOfOrganisation(input: $input)
  }
`;
export const GetClientDetails = /* GraphQL */ `
  query GetClientDetails($input: GetClientDetailsInput!) {
    GetClientDetails(input: $input)
  }
`;
export const GetProductDetails = /* GraphQL */ `
  query GetProductDetails($input: GetProductDetailsInput!) {
    GetProductDetails(input: $input)
  }
`;
export const ListCRMLogsOfProduct = /* GraphQL */ `
  query ListCRMLogsOfProduct($input: ListCRMLogsOfProductInput!) {
    ListCRMLogsOfProduct(input: $input)
  }
`;
export const ListCampaigns = /* GraphQL */ `
  query ListCampaigns($input: ListCampaignsInput!) {
    ListCampaigns(input: $input)
  }
`;
export const ListUniqueFieldsOfCampaign = /* GraphQL */ `
  query ListUniqueFieldsOfCampaign($input: ListUniqueFieldsOfCampaignInput!) {
    ListUniqueFieldsOfCampaign(input: $input)
  }
`;
export const GetCampaignDetails = /* GraphQL */ `
  query GetCampaignDetails($input: GetCampaignDetailsInput!) {
    GetCampaignDetails(input: $input)
  }
`;
export const ListCampaignData = /* GraphQL */ `
  query ListCampaignData($input: ListCampaignDataInput!) {
    ListCampaignData(input: $input)
  }
`;
export const GetPresignedURLExcelUpload = /* GraphQL */ `
  query GetPresignedURLExcelUpload($input: GetPresignedURLExcelUploadInput!) {
    GetPresignedURLExcelUpload(input: $input)
  }
`;
export const GetS3URLFileUpload = /* GraphQL */ `
  query GetS3URLFileUpload($input: GetS3URLFileUploadInput!) {
    GetS3URLFileUpload(input: $input)
  }
`;
export const ListMyOwnedTickets = /* GraphQL */ `
  query ListMyOwnedTickets($input: ListMyOwnedTicketsInput!) {
    ListMyOwnedTickets(input: $input)
  }
`;
export const ListAllTicketsOfCustomer = /* GraphQL */ `
  query ListAllTicketsOfCustomer($input: ListAllTicketsOfCustomerInput!) {
    ListAllTicketsOfCustomer(input: $input)
  }
`;
export const ListTicketDetails = /* GraphQL */ `
  query ListTicketDetails($input: ListTicketDetailsInput!) {
    ListTicketDetails(input: $input)
  }
`;
export const ListDelayedTickets = /* GraphQL */ `
  query ListDelayedTickets($input: ListDelayedTicketsInput!) {
    ListDelayedTickets(input: $input)
  }
`;
export const ListOrganisationEmailTemplates = /* GraphQL */ `
  query ListOrganisationEmailTemplates(
    $input: ListOrganisationEmailTemplatesInput!
  ) {
    ListOrganisationEmailTemplates(input: $input)
  }
`;
export const ListOrganisationStats = /* GraphQL */ `
  query ListOrganisationStats($input: ListOrganisationStatsInput!) {
    ListOrganisationStats(input: $input)
  }
`;
export const GetExotelAverageCallDuration = /* GraphQL */ `
  query GetExotelAverageCallDuration(
    $input: GetExotelAverageCallDurationInput!
  ) {
    GetExotelAverageCallDuration(input: $input)
  }
`;
export const GetExotelNumberOfCalls = /* GraphQL */ `
  query GetExotelNumberOfCalls($input: GetExotelNumberOfCallsInput!) {
    GetExotelNumberOfCalls(input: $input)
  }
`;
export const GetOrganisationCallStats = /* GraphQL */ `
  query GetOrganisationCallStats($input: GetOrganisationCallStatsInput!) {
    GetOrganisationCallStats(input: $input)
  }
`;
export const ListAgentStats = /* GraphQL */ `
  query ListAgentStats($input: ListAgentStatsInput!) {
    ListAgentStats(input: $input)
  }
`;
export const GetCustomerSatisfactionScore = /* GraphQL */ `
  query GetCustomerSatisfactionScore(
    $input: GetCustomerSatisfactionScoreInput!
  ) {
    GetCustomerSatisfactionScore(input: $input)
  }
`;
export const ListAllNotificationsOfUser = /* GraphQL */ `
  query ListAllNotificationsOfUser($input: ListAllNotificationsOfUserInput!) {
    ListAllNotificationsOfUser(input: $input)
  }
`;
export const ListOrganisationTicketFormFields = /* GraphQL */ `
  query ListOrganisationTicketFormFields(
    $input: ListOrganisationTicketFormFieldsInput!
  ) {
    ListOrganisationTicketFormFields(input: $input)
  }
`;
export const ListMasterTagsOfOrganisation = /* GraphQL */ `
  query ListMasterTagsOfOrganisation(
    $input: ListMasterTagsOfOrganisationInput!
  ) {
    ListMasterTagsOfOrganisation(input: $input)
  }
`;
export const ListSubTagsOfMasterTag = /* GraphQL */ `
  query ListSubTagsOfMasterTag($input: ListSubTagsOfMasterTagInput!) {
    ListSubTagsOfMasterTag(input: $input)
  }
`;
export const ListSubSubTagsOfSubTag = /* GraphQL */ `
  query ListSubSubTagsOfSubTag($input: ListSubSubTagsOfSubTagInput!) {
    ListSubSubTagsOfSubTag(input: $input)
  }
`;
export const ListTicketsOnTagsBasis = /* GraphQL */ `
  query ListTicketsOnTagsBasis($input: ListTicketsOnTagsBasisInput!) {
    ListTicketsOnTagsBasis(input: $input)
  }
`;
export const ListOrganisationKnowledgeBase = /* GraphQL */ `
  query ListOrganisationKnowledgeBase(
    $input: ListOrganisationKnowledgeBaseInput!
  ) {
    ListOrganisationKnowledgeBase(input: $input)
  }
`;
export const GenerateExcelReport = /* GraphQL */ `
  query GenerateExcelReport($input: GenerateExcelReportInput!) {
    GenerateExcelReport(input: $input)
  }
`;
export const GenerateExcelReportTickets = /* GraphQL */ `
  query GenerateExcelReportTickets($input: GenerateExcelReportTicketsInput!) {
    GenerateExcelReportTickets(input: $input)
  }
`;
export const GenerateExcelReportRftTickets = /* GraphQL */ `
  query GenerateExcelReportRftTickets(
    $input: GenerateExcelReportRftTicketsInput!
  ) {
    GenerateExcelReportRftTickets(input: $input)
  }
`;
export const GenerateExcelReportAgents = /* GraphQL */ `
  query GenerateExcelReportAgents($input: GenerateExcelReportAgentsInput!) {
    GenerateExcelReportAgents(input: $input)
  }
`;
export const FetchAgentReports = /* GraphQL */ `
  query FetchAgentReports($input: FetchAgentReportsInput!) {
    FetchAgentReports(input: $input)
  }
`;
export const FetchOrganisationReports = /* GraphQL */ `
  query FetchOrganisationReports($input: FetchOrganisationReportsInput!) {
    FetchOrganisationReports(input: $input)
  }
`;
export const ListAgentExternalTickets = /* GraphQL */ `
  query ListAgentExternalTickets($input: ListAgentExternalTicketsInput!) {
    ListAgentExternalTickets(input: $input)
  }
`;
export const ListUserSessionDetails = /* GraphQL */ `
  query ListUserSessionDetails($input: ListUserSessionDetailsInput!) {
    ListUserSessionDetails(input: $input)
  }
`;
export const SearchForLead = /* GraphQL */ `
  query SearchForLead($input: SearchForLeadInput!) {
    SearchForLead(input: $input)
  }
`;
export const FetchUsersOfOrganisation = /* GraphQL */ `
  query FetchUsersOfOrganisation($input: FetchUsersOfOrganisationInput!) {
    FetchUsersOfOrganisation(input: $input)
  }
`;
export const GenerateExcelReportSessions = /* GraphQL */ `
  query GenerateExcelReportSessions($input: GenerateExcelReportSessionsInput!) {
    GenerateExcelReportSessions(input: $input)
  }
`;
export const FetchChildTickets = /* GraphQL */ `
  query FetchChildTickets($input: FetchChildTicketsInput!) {
    FetchChildTickets(input: $input)
  }
`;
export const SearchTicket = /* GraphQL */ `
  query SearchTicket($input: SearchTicketInput!) {
    SearchTicket(input: $input)
  }
`;
export const ListOrganisationContacts = /* GraphQL */ `
  query ListOrganisationContacts($input: ListOrganisationContactsInput!) {
    ListOrganisationContacts(input: $input)
  }
`;
export const ListLeadsOfOrganisation = /* GraphQL */ `
  query ListLeadsOfOrganisation($input: ListLeadsOfOrganisationInput!) {
    ListLeadsOfOrganisation(input: $input)
  }
`;
export const SearchLeadsOpenSearch = /* GraphQL */ `
  query SearchLeadsOpenSearch($input: SearchLeadsOpenSearchInput!) {
    SearchLeadsOpenSearch(input: $input)
  }
`;
export const SearchLeadsOpensearchV2 = /* GraphQL */ `
  query SearchLeadsOpensearchV2($input: SearchLeadsOpensearchV2Input!) {
    SearchLeadsOpensearchV2(input: $input)
  }
`;
export const OpenSearch_tags2 = /* GraphQL */ `
  query OpenSearch_tags2($input: OpenSearch_tags2Input!) {
    OpenSearch_tags2(input: $input)
  }
`;
export const ListTicketsv2 = /* GraphQL */ `
  query ListTicketsv2($input: ListTicketsv2Input!) {
    ListTicketsv2(input: $input)
  }
`;
export const ListMyRFTTickets = /* GraphQL */ `
  query ListMyRFTTickets($input: ListMyRFTTicketsInput!) {
    ListMyRFTTickets(input: $input)
  }
`;
export const ListDialIDs = /* GraphQL */ `
  query ListDialIDs($input: ListDialIDsInput!) {
    ListDialIDs(input: $input)
  }
`;
export const ListWorkqueueCategories = /* GraphQL */ `
  query ListWorkqueueCategories($input: ListWorkqueueCategoriesInput!) {
    ListWorkqueueCategories(input: $input)
  }
`;
export const ListSIPAgentGroups = /* GraphQL */ `
  query ListSIPAgentGroups($input: ListSIPAgentGroupsInput!) {
    ListSIPAgentGroups(input: $input)
  }
`;
export const ListSIPGroupAgents = /* GraphQL */ `
  query ListSIPGroupAgents($input: ListSIPGroupAgentsInput!) {
    ListSIPGroupAgents(input: $input)
  }
`;
export const ListUnassignedSipAgents = /* GraphQL */ `
  query ListUnassignedSipAgents($input: ListUnassignedSipAgentsInput!) {
    ListUnassignedSipAgents(input: $input)
  }
`;
export const ListCampaignsOfUser = /* GraphQL */ `
  query ListCampaignsOfUser($input: ListCampaignsOfUserInput!) {
    ListCampaignsOfUser(input: $input)
  }
`;
export const FetchUserCampaignLeads = /* GraphQL */ `
  query FetchUserCampaignLeads($input: FetchUserCampaignLeadsInput!) {
    FetchUserCampaignLeads(input: $input)
  }
`;
export const ListLeadHistory = /* GraphQL */ `
  query ListLeadHistory($input: ListLeadHistoryInput!) {
    ListLeadHistory(input: $input)
  }
`;
export const CampaignLeadSearch = /* GraphQL */ `
  query CampaignLeadSearch($input: CampaignLeadSearchInput!) {
    CampaignLeadSearch(input: $input)
  }
`;
export const FetchOrganisationAuditLogs = /* GraphQL */ `
  query FetchOrganisationAuditLogs($input: FetchOrganisationAuditLogsInput!) {
    FetchOrganisationAuditLogs(input: $input)
  }
`;
export const FetchOrganisationCallRecords = /* GraphQL */ `
  query FetchOrganisationCallRecords(
    $input: FetchOrganisationCallRecordsInput!
  ) {
    FetchOrganisationCallRecords(input: $input)
  }
`;
export const GenerateExcelArchiveRftTickets = /* GraphQL */ `
  query GenerateExcelArchiveRftTickets(
    $input: GenerateExcelArchiveRftTicketsInput!
  ) {
    GenerateExcelArchiveRftTickets(input: $input)
  }
`;
