import Vue from "vue";
import VueRouter from "vue-router";
// import LoginPage from "../views/MainHomePages/LoginPage.vue";
import LoginPage from "../views/MainHomePages/LoginPageNew.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "LoginPage",
    component: LoginPage,
    meta: { title: "HelloWRK - Login" },
  },

  {
    path: "/SignUp",
    name: "SignUp",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ "../views/MainHomePages/SignUp.vue"),
  },
  {
    path: "/LandingPage",
    name: "LandingPage",
    props: true,
    component: () => import(/* webpackChunkName: "about" */ "../views/MainHomePages/LandingPage.vue"),
    children: [
      {
        path: "/Dashboard",
        name: "Dashboard",
        meta: { title: "HelloWRK - Dashboard" },
        component: () => import(/* webpackChunkName: "about" */ "../views/ChildHomePages/DashBoard.vue"),
      },
      {
        path: "/Campaigns",
        name: "Campaigns",
        meta: { title: "HelloWRK - Campaigns" },

        component: () => import(/* webpackChunkName: "about" */ "../views/ChildHomePages/Campaigns.vue"),
      },
      {
        path: "/Tickets",
        name: "Tickets",
        meta: { title: "HelloWRK - Tickets" },

        component: () => import(/* webpackChunkName: "about" */ "../views/ChildHomePages/Tickets.vue"),
        children: [
          {
            path: ":ticket-display-id",
            name: "view-ticket-details",
            meta: { title: "HelloWRK - View Ticket Details" },

            component: () => import(/* webpackChunkName: "ticket-details" */ "../components/Tickets/Cards/ViewTicketDeatils.vue"),
          },
        ],
      },

      {
        path: "/tags",
        name: "tags",
        meta: { title: "HelloWRK - Tags" },

        component: () => import(/* webpackChunkName: "about" */ "@/components/Tickets/Cards/Tags/TagsPage.vue"),
      },
      {
        path: "/MyWorkQueue",
        name: "My Work Queue",
        meta: { title: "HelloWRK - My Work Queue" },

        component: () => import(/* webpackChunkName: "about" */ "../views/ChildHomePages/MyWorkQueue.vue"),
      },
      {
        path: "/Agents",
        name: "Agents",
        meta: { title: "HelloWRK - Agents" },

        component: () => import(/* webpackChunkName: "about" */ "../views/ChildHomePages/Agents.vue"),
      },
      {
        path: "/WFDesigner",
        name: "WF Designer",
        meta: { title: "HelloWRK - Workflow Designer" },

        component: () => import(/* webpackChunkName: "about" */ "../views/ChildHomePages/WorkQueDesigner.vue"),
      },
      {
        path: "/FieldRepository",
        name: "Field Repository",
        meta: { title: "HelloWRK - Field Repository" },

        component: () => import(/* webpackChunkName: "about" */ "../views/ChildHomePages/FieldRepository.vue"),
      },
      {
        path: "/Form",
        name: "Form",
        meta: { title: "HelloWRK - From" },

        component: () => import(/* webpackChunkName: "about" */ "../views/ChildHomePages/OwnForm.vue"),
      },
      {
        path: "/CRMData",
        name: "CRM Data",
        meta: { title: "HelloWRK - CRM Data" },

        component: () => import(/* webpackChunkName: "about" */ "../views/ChildHomePages/CRMData.vue"),
      },
      {
        path: "/CurateData",
        name: "Curate Data",
        meta: { title: "HelloWRK - Curate Data" },

        component: () => import(/* webpackChunkName: "about" */ "../views/ChildHomePages/CurateData.vue"),
      },
      {
        path: "/Roles",
        name: "Roles",
        meta: { title: "HelloWRK - Roles" },

        component: () => import(/* webpackChunkName: "about" */ "../views/ChildHomePages/Roles.vue"),
      },
      {
        path: "/Users",
        name: "Users",
        meta: { title: "HelloWRK - Users" },

        component: () => import(/* webpackChunkName: "about" */ "../views/ChildHomePages/Users.vue"),
      },
      {
        path: "/AgentGroup",
        name: "Agent Group",
        meta: { title: "HelloWRK - Agent Groups" },

        component: () => import(/* webpackChunkName: "about" */ "../views/ChildHomePages/AgentGroup.vue"),
      },
      {
        path: "/Departments",
        name: "Departments",
        meta: { title: "HelloWRK - Departmets" },

        component: () => import(/* webpackChunkName: "about" */ "../views/ChildHomePages/Departments.vue"),
      },
      {
        path: "/Settings",
        name: "Settings",
        meta: { title: "HelloWRK - Settings" },

        component: () => import(/* webpackChunkName: "about" */ "../views/ChildHomePages/Settings.vue"),
      },
      {
        path: "/RFT",
        name: "RFT",
        meta: { title: "HelloWRK - RFT" },

        component: () => import(/* webpackChunkName: "about" */ "../views/ChildHomePages/RFTPage.vue"),
      },
      {
        path: "/Clients",
        name: "Clients",
        meta: { title: "HelloWRK - Clients" },

        component: () => import(/* webpackChunkName: "about" */ "../views/ChildHomePages/Clients.vue"),
      },
      {
        path: "/IncomingCalls",
        name: "Incoming Calls",

        component: () => import(/* webpackChunkName: "about" */ "../views/ChildHomePages/IncomingCalls.vue"),
      },
      {
        path: "/view-ticket/:ticketid",
        name: "view-ticket",
        component: () => import("../components/Tickets/Cards/ViewTicketDeatils.vue"),
        props: true,
        meta: { title: "HelloWRK - view-ticket" },
      },
      {
        path: "/KnowledgeBase",
        name: "Knowledge Base",
        meta: { title: "HelloWRK - Knowledgebase" },
        component: () => import("../views/ChildHomePages/KnowledgeBase.vue"),
        props: true,
      },
      {
        path: "/Dialer",
        name: "Dialer",
        meta: { title: "HelloWRK - Dialer" },
        component: () => import("../views/ChildHomePages/CampaignDialer.vue"),
        props: true,
      },
      {
        path: "/AuditLogs",
        name: "Audit Logs",
        meta: { title: "HelloWRK - Audit Logs" },
        component: () => import("../views/ChildHomePages/AuditLogs.vue"),
        props: true,
      },
      {
        path: "/CallLogs",
        name: "Call Logs",
        meta: { title: "HelloWRK - Call Recordings" },
        component: () => import("../views/ChildHomePages/CallRecordings.vue"),
        props: true,
      },
    ],
  },
  {
    path: "/no-access",
    name: "no-access",
    meta: { title: "HelloWRK- No Access" },
    component: () => import(/* webpackChunkName: "about" */ "../views/MainHomePages/NoAccess.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
